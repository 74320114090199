var truncate = require("../product/_initTruncate");
const commonFeature = require("../components/commonFeature");
var debounce = require("lodash/debounce");

/**
 * Truncate all elements inside the container after grid update
 *
 * @param {Object} $result - jQuery DOM element
 * @param {string} selector - DOM element which contains elements to be truncated
 * @return {undefined}
 */
function handleGridUpdate($result, selector) {
    truncate.truncateInsideContainer($(selector));
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        ".product-grid": handleGridUpdate
    };

    // Update DOM elements that do not require special handling
    [
        ".refinement-bar",
        ".refinement-bar-desktop",
        ".sort-order-menu",
        ".footer-bar",
        ".header.search-results-title",
        ".product-grid",
        ".show-more",
        ".filter-bar"
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    if (!$("span.search-result-count").text()) {
        $(".no-result").removeClass("d-none");
    }

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results, selector);
    });
}

/**
 * Displays the appropriate image (mobile or desktop) or a placeholder for search result articles
 */
function searchArticleImage() {
    $(".content-result__image-container").each(function () {
        var container = $(this);
        var desktopImage = container.data("desktopImage");
        var mobileImage = container.data("mobileImage");

        function updateImageDisplay() {
            var windowWidth = $(window).width();
            var imgHtml = "";

            if (windowWidth > 1023 && desktopImage) {
                imgHtml = `<img src="${desktopImage}" alt="Desktop Image" class="content-result__image">`;
            } else if (windowWidth <= 1023 && mobileImage) {
                imgHtml = `<img src="${mobileImage}" alt="Mobile Image" class="content-result__image">`;
            } else {
                var placeholderClass = windowWidth <= 1023 ? "content-result__image-placeholder mobile" : "content-result__image-placeholder";
                imgHtml = `<div class="${placeholderClass}"></div>`;
            }

            container.html(imgHtml);
        }

        updateImageDisplay();

        $(window).resize(updateImageDisplay);
    });
};


/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data("url");
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: "GET",
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
            searchArticleImage();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $("<div>").append($(response));
    var sortOptions = $tempDom.find(".grid-footer").data("sort-options").options;
    sortOptions.forEach(function (option) {
        $("option." + option.id).val(option.url);
        $("input#" + option.id).val(option.url);
    });
}

function openFiltersDialog() {
    $(".refinement-bar").addClass("in");
    $("body").addClass("modal-open");
    $(".modal-background").addClass("close-refinements-modal").show();
    $(".refinement-bar").siblings().attr("aria-hidden", true);
    $(".refinement-bar").closest(".row").siblings().attr("aria-hidden", true);
    $(".refinement-bar").closest(".tab-pane.active").siblings().attr("aria-hidden", true);
    $(".refinement-bar").closest(".container.search-results").siblings().attr("aria-hidden", true);
}

function closeFiltersDialog() {
    $(".refinement-bar").removeClass("in");
    $("body").removeClass("modal-open");
    $(".modal-background").removeClass("close-refinements-modal").hide();
    $(".refinement-bar").siblings().attr("aria-hidden", false);
    $(".refinement-bar").closest(".row").siblings().attr("aria-hidden", false);
    $(".refinement-bar").closest(".tab-pane.active").siblings().attr("aria-hidden", false);
    $(".refinement-bar").closest(".container.search-results").siblings().attr("aria-hidden", false);
}

function clearPDComponents() {
    var $productGrid = $(".product-grid");
    if ($productGrid.length > 1) {
        $productGrid.each(function (i) {
            if (i == 0) {
                return;
            }
            $(this).remove();
        });
    }
}

function initReadMoreDesc() {
    var description = $(".content-result-description .content");

    description.each(function () {
        var $this = $(this);
        var $descriptionInner = $this.closest(".description-inner");
        var $readMoreBtn = $this.closest(".content-result-description").find(".button-read-more");
        var textHeight = $this.height();
        var containerHeight = $descriptionInner.height();

        $readMoreBtn.addClass("d-none");
        $descriptionInner.removeClass("expanded truncated");

        if (textHeight > containerHeight) {
            $readMoreBtn.removeClass("d-none");
            $descriptionInner.addClass("truncated");
        }
    });
}

function getRefinebarTop() {
    return $("#product-search-results").offset().top - 76;
}

/**
 * updates view-item-list gtm object upon load more on PLP
 * @param {*} response
 * @param {JSON} gtmViewItemList
 *
 */
function updateGTMViewItemList(response, gtmViewItemList) { // eslint-disable-line consistent-return
    var $tempDom = $("<div>").append($(response));
    var gtmLoadMore = $tempDom.find(".grid-footer").data("gtm-load-more");

    if (gtmViewItemList && gtmLoadMore && gtmLoadMore.items) {
        var gtmViewItemListObj = JSON.parse(gtmViewItemList);
        var gtmItems = gtmViewItemListObj.items.concat(gtmLoadMore.items);
        // update item index in the extended item list
        gtmItems.map(function (item, index) { // eslint-disable-line array-callback-return
            item.index = index; // eslint-disable-line no-param-reassign
        });
        gtmViewItemListObj.items = gtmItems;

        // push object to view_item_list GTM dataLayer event
        $("body").trigger("gtm:standartEvent", {
            event: "view_item_list",
            data: gtmViewItemListObj
        });

        var gtmObj = {};
        try {
            gtmObj = JSON.stringify(gtmViewItemListObj);
        } catch (error) {
            return gtmObj;
        }

        // update data-view-item-list with the extended object
        $(".gtm-view-item-list").data("view-item-list", gtmObj);
    }
}

function updateRefinementsURL(sortingOptionID) {
    var $customControlInputs = $(".refinements .filter-item .refinement-item .custom-checkbox, .refinements .filter-item .refinement-item .custom-control-input");
    var currentLocation = window.location.href;
    for (var i = 0; i < $customControlInputs.length; i++) {
        var currentFilter = $customControlInputs[i];
        var isColorRefinement = $(currentFilter).closest(".color-attribute").length > 0;
        var $elementToUpdate = isColorRefinement ? $(currentFilter).closest(".form-group") : $(currentFilter);
        var currentURL = $elementToUpdate.attr("data-url");

        if (currentURL) {
            var newURL = "";

            if (currentURL.indexOf("sopt") > -1) {
                var currenthref = new URL(currentURL, currentLocation);
                currenthref.searchParams.set("sopt", sortingOptionID);
                newURL = currenthref.toString();
            } else {
                newURL = currentURL + "&sopt=" + sortingOptionID;
            }

            $elementToUpdate.attr("data-url", newURL);
        }
    }

    var $filterButtons = $(".filter-bar .filter-value button, .reset.button-link");
    for (var b = 0; b < $filterButtons.length; b++) {
        var currentButton = $filterButtons[b];
        var resetURL = $(currentButton).attr("data-href");

        if (resetURL) {
            var newResetURL = "";

            if (resetURL.indexOf("sopt") > -1) {
                var resethref = new URL(resetURL, currentLocation);
                resethref.searchParams.set("sopt", sortingOptionID);
                newResetURL = resethref.toString();
            } else {
                newResetURL = resetURL + "&sopt=" + sortingOptionID;
            }

            $(currentButton).attr("data-href", newResetURL);
        }
    }
}

/**
 * toggles filterable refinments
 * @param {Object} input - input field DOM element
 */
function toggleFilterableRefinements(input) {
    var inputValue = $(input).val();
    if (inputValue && inputValue.length > 0) {
        $(input).siblings(".values").find("input").each(function () {
            var sku = $(this).val();

            if (sku && sku.toLowerCase().includes(inputValue.toLowerCase())) {
                $(this).closest("li.refinement-item").removeClass("d-none");
            } else {
                $(this).closest("li.refinement-item").addClass("d-none");
            }
        });
    } else {
        $(input).siblings(".values").find("li.refinement-item").removeClass("d-none");
    }
}

function setDropdownMaxHeight(dropdownMenu) {
    const filterButton = dropdownMenu.closest(".filter-item");
    const filterButtonOffset = filterButton.offset().top - $(window).scrollTop();
    const filterOffset = filterButtonOffset + filterButton.height() + 30;
    dropdownMenu.css({ maxHeight: `calc(100vh - ${filterOffset}px)` });
}

module.exports = {
    collapseFilters: function () {
        $(".refinement-bar").on("click", ".filter-item-title", function () {
            if ($(this).closest(".filter-item").hasClass("active")) {
                return;
            }
            var $sortEl = $(".refinement-bar").find(".sort-order");
            if ($sortEl && $sortEl.hasClass("active")) {
                $sortEl.find(".button-expand").attr("aria-expanded", false);
                $sortEl.removeClass("active");
            }

            var filterItems = $(".filter-item-title");
            filterItems.each(function () {
                if ($(this).closest(".filter-item").hasClass("active")) {
                    $(this).attr("aria-expanded", false);
                    $(this).closest(".filter-item").removeClass("active");
                }
            });
        });

        $(".refinement-bar").on("click", ".sort-order .button-expand", function () {
            if ($(this).closest(".sort-order").hasClass("active")) {
                return;
            }

            var filterItems = $(".filter-item-title");
            filterItems.each(function () {
                if ($(this).closest(".filter-item").hasClass("active")) {
                    $(this).attr("aria-expanded", false);
                    $(this).closest(".filter-item").removeClass("active");
                }
            });
        });

        $(".container").on("show.bs.dropdown", ".filter-item", function () {
            const dropdownMenu = $(this).find(".dropdown-menu");
            setDropdownMaxHeight(dropdownMenu);
        });
    },

    applyFilters: function () {
        $(".refinement-bar").on("click", ".apply-filter-btn", function () {
            closeFiltersDialog();
        });
    },

    filter: function () {
        $(".container").on("click", "button.filter-results", function () {
            openFiltersDialog();
            $(".refinement-bar .close").focus();
        });
    },

    closeRefinements: function () {
        $(".container").on("click", ".refinement-bar button.close, .close-refinements-modal", function () {
            closeFiltersDialog();
            $(".filter-results").focus();
        });

        $(document).on("click", ".close-refinements-modal", function () {
            closeFiltersDialog();
            $(".filter-results").focus();
        });
    },

    resize: function () {
        $(window).on("breakpoint:change", function () {
            closeFiltersDialog();
        });
    },

    sort: function () {
        // Handle sort order menu selection
        $(".container").on("change", "[name=sort-order]", function (e) {
            e.preventDefault();

            var sortingOptionID = $(this).find(":selected").data("id");

            $.spinner().start();
            $(this).trigger("search:sort", this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: "GET",
                success: function (response) {
                    clearPDComponents();
                    var $productGrid = $(".product-grid");
                    $productGrid.empty().html(response);
                    truncate.truncateInsideContainer($productGrid);
                    updateRefinementsURL(sortingOptionID);
                    if ($(".permalink").length) {
                        window.history.replaceState(null, null, window.location.href.split("?")[0] + "?" + $(".permalink").val().split("?")[1]);
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });

        $(".container").on("change", ".sort-item-input", function (e) {
            e.preventDefault();
            var input = this;
            var value = input.value;
            var selectedSortRule = $(input).attr("id");
            $(".sort-item-input").prop("checked", false);
            $(`input[id="${selectedSortRule}"]`).prop("checked", true);
            $.spinner().start();
            $(input).trigger("search:sort", value);
            if (value) {
                $.ajax({
                    url: value,
                    data: { selectedUrl: value },
                    method: "GET",
                    success: function (response) {
                        clearPDComponents();
                        var $productGrid = $(".product-grid");
                        $productGrid.empty().html(response);
                        truncate.truncateInsideContainer($productGrid);
                        updateRefinementsURL(selectedSortRule);
                        if ($(".permalink").length) {
                            window.history.replaceState(null, null, window.location.href.split("?")[0] + "?" + $(".permalink").val().split("?")[1]);
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },

    showMore: function () {
        // Show more products
        $(".container").on("click", ".show-more button", function (e) {
            e.stopPropagation();
            var $this = $(this);
            var showMoreUrl = $this.data("url");
            e.preventDefault();

            $.spinner().start();
            $this.trigger("search:showMore", e);
            $.ajax({
                url: showMoreUrl,
                data: {
                    selectedUrl: showMoreUrl,
                    showMore: true
                },
                method: "GET",
                success: function (response) {
                    var $gridFooter = $this.closest(".grid-footer");
                    var $gridContainer = $gridFooter.closest(".product-grid");
                    var gtmViewItemList = $gridContainer.find(".gtm-view-item-list").attr("data-view-item-list");
                    $gridFooter.replaceWith(response);
                    truncate.truncateInsideContainer($gridContainer);
                    updateSortOptions(response);
                    updateGTMViewItemList(response, gtmViewItemList);
                    $.spinner().stop();
                    if ($(".permalink").length) {
                        window.history.pushState(null, null, $(".permalink").val());
                    }
                    affirm.ui.ready(function () {
                        affirm.ui.refresh();
                    });
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    initTruncate: truncate.initTruncate,

    applyFilter: function () {
        $(".container").on("change", ".refinement .filter-value-input", function (e) {
            $.spinner().start();

            $(this).trigger("search:filter", e);
            var $activeFilterID = $(".filter-item.refinement.show").find(".content.show").attr("id");
            var $activeFilterIDSideBar = $(".filter-item.refinement.active").find(".card-body").attr("id");
            var sortingOptionID = "";
            if (commonFeature.isBreakpoint("<md")) {
                sortingOptionID = $(".sort-order .sort-item-input:checked").attr("id");
            } else {
                sortingOptionID = $(".sort-order-menu select[name=sort-order] :selected").data("id");
            }

            $.ajax({
                url: $(this).parent().data("url"),
                data: {
                    page: $(".grid-footer").data("page-number"),
                    selectedUrl: $(this).parent().data("url")
                },
                method: "GET",
                success: function (response) {
                    clearPDComponents();

                    parseResults(response);

                    if ($activeFilterID) {
                        $(".filter-item.refinement." + $activeFilterID + " > button:visible").click();
                    }

                    if ($activeFilterIDSideBar) {
                        $(".filter-item.refinement." + $activeFilterIDSideBar + " button:visible").click();
                    }

                    if ($(".permalink").length) {
                        window.history.pushState(null, null, $(".permalink").val());
                    }

                    var $activeDropdownFilter = $(".filter-item.refinement.show");
                    if ($activeDropdownFilter.length) {
                        const dropdownMenu = $activeDropdownFilter.find(".dropdown-menu");
                        setDropdownMaxHeight(dropdownMenu);
                    }

                    window.scrollTo(0, getRefinebarTop());
                    updateRefinementsURL(sortingOptionID);

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    resetFilter: function () {
        $(".container").on("click", ".filter-bar button.reset, .filter-value button", function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $searchContainer = $(".search-results");
            var sortingOptionID = "";
            if (commonFeature.isBreakpoint("<md")) {
                sortingOptionID = $(".sort-order .sort-item-input:checked").attr("id");
            } else {
                sortingOptionID = $(".sort-order-menu select[name=sort-order] :selected").data("id");
            }

            $.spinner().start();
            $(this).trigger("search:filter", e);
            $.ajax({
                url: $(this).data("href"),
                data: {
                    page: $(".grid-footer").data("page-number"),
                    selectedUrl: $(this).data("href")
                },
                method: "GET",
                success: function (response) {
                    parseResults(response);

                    $searchContainer.removeClass("is-filter-applied");

                    if ($(".permalink").length) {
                        window.history.replaceState(null, null, window.location.href.split("?")[0] + "?" + $(".permalink").val().split("?")[1]);
                    }

                    window.scrollTo(0, getRefinebarTop());
                    updateRefinementsURL(sortingOptionID);

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    clearFiltersDialog: function () {
        $(".refinement-bar").on("click", "button.reset-filter-dialog", function (e) {
            e.preventDefault();
            e.stopPropagation();

            $.spinner().start();
            $(this).trigger("search:filter", e);
            $.ajax({
                url: $(this).data("href"),
                data: {
                    page: $(".grid-footer").data("page-number"),
                    selectedUrl: $(this).data("href")
                },
                method: "GET",
                success: function (response) {
                    parseResults(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showContentTab: function () {
        // Display content results from the search
        $(".container").on("click", ".content-search", function () {
            if ($("#content-search-results").html() === "") {
                getContent($(this), $("#content-search-results"));
                setTimeout(function () {
                    initReadMoreDesc();
                }, 1000);
            }
        });

        // Display the next page of content results from the search
        $(".container").on("click", ".show-more-content button", function () {
            getContent($(this), $("#content-search-results"));
            setTimeout(function () {
                initReadMoreDesc();
            }, 1000);
            $(".show-more-content").remove();
        });
    },

    readMoreDescription: function () {
        $("body").on("click", ".button-read-more", function () {
            var $this = $(this);
            var $descriptionInner = $this.closest(".content-result-description").find(".description-inner");

            $this.addClass("d-none");
            $descriptionInner.addClass("expanded").removeClass("truncated");
        });

        $(window).on("breakpoint:change", function () {
            setTimeout(function () {
                initReadMoreDesc();
            }, 1000);
        });
    },

    addIdtoLink: function () {
        $("#product-search-results").on("click", ".product", function () {
            window.history.pushState(null, null, window.location.href.split("#")[0] + "#" + this.id);
        });
    },

    filterableRefinements: function () {
        var debouncetoggleFilterableRefinements = debounce(toggleFilterableRefinements, 300);
        $(document).on("input", ".filter-refinement-input", function () {
            debouncetoggleFilterableRefinements(this);
        });
    }
};
