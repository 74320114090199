require("dotdotdot");

/**
 * Truncate block with "..."
 *
 * @param {string} $el - DOM element which should be truncated
 * @return {undefined}
 */
function truncateBlock($el) {
    $el.dotdotdot({
        watch: true,
        truncate: "word"
    });
}

/**
 * Truncate all elements inside the container
 *
 * @param {string} $container - DOM element which contains elements to be truncated
 * @return {undefined}
 */
function truncateInsideContainer($container) {
    if (!$container) {
        $container = $("body"); // eslint-disable-line
    }
    setTimeout(function () {
        $container.find(".pdp-link > a").each(function () {
            truncateBlock($(this));
        });
    }, 1000);
}

/**
 * Initialize truncating of all elements inside the container
 *
 * @param {string} $container - DOM element which contains elements to be truncated
 * @return {undefined}
 */
function initTruncate($container) {
    if (!$container) {
        $container = $(".product-grid"); // eslint-disable-line
    }
    truncateInsideContainer($container);
}

module.exports = {
    truncateInsideContainer: truncateInsideContainer,
    initTruncate: initTruncate,
};
